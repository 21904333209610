import { DocumentList } from '@fingo/lib/components/lists';
import { useOrderingLucilaPreColumns } from '@fingo/lib/constants';
import { ORDERING_PURCHASE_ORDERS } from '@fingo/lib/graphql';
import React, { useState } from 'react';
import SimulationsHeader from './headers/SimulationsHeader';

const SimulationsManager = () => {
  const [selectedPurchaseOrderIds, setSelectedPurchaseOrderIds] = useState([]);
  return (
    <DocumentList
      trackerId="ORDERING_PREOFFER"
      type="ordering"
      headerTitle="Simulaciones de ordering"
      queryDocument={ORDERING_PURCHASE_ORDERS}
      emitter
      customVariables={{
        inSimulation: true,
        companyId: null,
      }}
      includeHeaders={[
        'orderNumber',
        'company_MasterEntity_Name',
        'purchaser_Name',
        'publicationDate',
        'totalAmount',
      ]}
      mobileHeaders={[
        'orderNumber',
        'purchaser_Name',
        'totalAmount',
        'orderingsimulation_OrderingMonthlyRate',
        'orderingInvoiceDate',
      ]}
      showFilters
      defaultFilterProps={{
        showPreoffersFilters: true,
        showStatesFilter: false,
        showDatesFilter: false,
        showCompanyIssuerFilter: true,
        defaultFilterDays: 30,
      }}
      flexEndButtons={() => (
        <SimulationsHeader
          selectedPurchaseOrderIds={selectedPurchaseOrderIds}
          setSelectedPurchaseOrderIds={setSelectedPurchaseOrderIds}
        />
      )}
      selectionModel={selectedPurchaseOrderIds}
      onSelectionModelChange={(ids) => setSelectedPurchaseOrderIds(ids)}
      checkboxSelection
      onCompletedSetLength={(data) => data.purchaseOrders.totalCount}
      onCompletedSetRows={(data) => data.purchaseOrders.edges.map((edge) => (edge.node))}
      preColumns={useOrderingLucilaPreColumns()}
      initialOrderBy="-publicationDate"
    />
  );
};

export default SimulationsManager;
