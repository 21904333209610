import RaiseOperativeRequestIconButton from '@fingo/lib/views/operativeRequest/RaiseOperativeRequestIconButton';
import usePurchaseOrders from '@fingo/lib/hooks/usePurchaseOrders';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import AddActionButton from '../../../../../components/buttons/AddActionButton';
import OperativeMailButton from '../../../../../components/buttons/OperativeMailButton';
import PendingPurchaseOrdersActions from '../pending-purchase-orders/PendingPurchaseOrdersActions';

const OrderingCollectionsHeader = ({ selectedPurchaseOrderIds, setSelectedPurchaseOrderIds }) => {
  const { purchaseOrders } = usePurchaseOrders({
    skip: !selectedPurchaseOrderIds.length,
    variables: { id_In: selectedPurchaseOrderIds, inCollection: true },
  });
  const disabled = useMemo(
    () => !selectedPurchaseOrderIds.length
      || [...new Set(purchaseOrders.map((oc) => oc.company.rut))].length > 1,
    [purchaseOrders],
  );
  const company = useMemo(() => purchaseOrders[0]?.company, [purchaseOrders]);
  return (
    <Grid direction="row" container justifyContent="flex-end" alignItems="center">
      <Grid item>
        <RaiseOperativeRequestIconButton
          documentType="ordering"
          selectedInvoices={selectedPurchaseOrderIds}
          setDocumentIds={setSelectedPurchaseOrderIds}
        />
      </Grid>
      <Grid container direction="column" alignItems="stretch" maxWidth="250px">
        <Grid item>
          <PendingPurchaseOrdersActions
            selectedPurchaseOrder={purchaseOrders}
            setSelectedPurchaseOrderIds={setSelectedPurchaseOrderIds}
            disabled={disabled}
            company={company}
          />
        </Grid>
        <Grid item>
          <AddActionButton
            managerIds={purchaseOrders.map((oc) => oc.collectionManager.id)}
            operationType="COLLECTION"
            disabled={disabled}
            masterEntityTarget={company}
          />
        </Grid>
        <Grid item>
          <OperativeMailButton
            selectedDocuments={purchaseOrders}
            operationType="COLLECTION"
            documentType="ordering"
            disabled={disabled}
            masterEntityTarget={company}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

OrderingCollectionsHeader.propTypes = {
  selectedPurchaseOrderIds: PropTypes.arrayOf(PropTypes.number),
  setSelectedPurchaseOrderIds: PropTypes.func.isRequired,
};

OrderingCollectionsHeader.defaultProps = {
  selectedPurchaseOrderIds: [],
};

export default OrderingCollectionsHeader;
